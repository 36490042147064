.selfOrder {
    .days {
        margin: 15px 0;

        a {
            padding: 8px;
            margin: 5px;
            background-color: rgb(225, 225, 225);
            border-radius: 10px;
            font-size: 18px;
            transition-property: background-color;
            transition-duration: 150ms;

            &:hover,
            &.selected {
                background-color: rgb(200, 200, 200);
            }
        }
    }

    .order {
        display: grid;
        grid-template-columns: max-content max-content;
        width: max-content;
        margin: 0 auto;

        .list {
            min-width: 20vw;
            padding: 10px;
            margin: 10px;
            background-color: rgb(215, 215, 215);
            border-radius: 15px;

            .list-title {
                font-weight: bold;

                i {
                    display: none;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }

            &:hover .list-title i {
                display: unset;
            }

            .item {
                display: flex;
                margin: 10px 0;
                background-color: rgb(255, 255, 255);
                border-radius: 7px;
                user-select: none;

                span {
                    padding: 5px;
                    flex-grow: 1;
                }

                input {
                    border: none;
                    flex-grow: 1;
                    font-size: 14px;
                }

                i {
                    display: none;
                    margin: auto 5px;
                    cursor: pointer;
                }

                &:hover i,
                .editing {
                    display: unset;
                }
            }

            .new {
                margin: 10px 0;
                display: flex;
                width: 100%;
                background-color: white;
                border-radius: 7px;

                input {
                    border: none;
                    flex-grow: 1;
                    font-size: 14px;
                }

                i {
                    margin: auto 5px;
                    cursor: pointer;
                    font-size: 1.2em;
                }
            }

            .dragging {
                background-color: rgb(240, 240, 240);
            }
        }
    }
}

.selfOrderDisplay {
    padding: 15px;

    .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;

        img {
            width: 200px;
        }

        span {
            margin: auto 0;
            margin-left: 25px;
            font-size: 42px;
        }
    }

    .lists {
        display: flex;
        justify-content: center;

        .list {
            width: 35%;
            padding: 10px;
            margin: 10px;
            background-color: rgb(200, 200, 200);
            border-radius: 15px;

            .list-title {
                font-size: 26px;
            }

            .item {
                margin: 10px 0;
                padding: 5px;
                background-color: white;
                border-radius: 10px;
                font-size: 18px;
            }
        }
    }

    .event {
        display: inline-block;
        margin-top: 20px;
        padding: 15px;
        background-color: rgb(200, 200, 200);
        border-radius: 15px;
        font-size: 28px;
    }

    .date {
        position: absolute;
        font-size: 38px;
        bottom: 15px;
        right: 15px;
    }
}