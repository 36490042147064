.login {
    display: inline-block;
    margin-top: 20px;
    padding: 15px;
    background-color: rgb(225, 225, 225);
    border-radius: 15px;

    button {
        background-color: rgb(185, 185, 185);

        &:hover {
            background-color: rgb(170, 170, 170);
        }
    }
}
