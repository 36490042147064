.ip {
    .print {
        position: absolute;
        right: 25px;
        font-size: 18px;

        i {
            margin-left: 0;
            font-size: 14px;
        }
    }

    .type {
        margin-top: 15px;
        font-size: 26px;
    }

    .category {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 22px;
    }

    .ip {
        font-size: 18px;
    }

    i {
        margin-left: 7px;
        font-size: 0.65em;
        cursor: pointer;
    }

    .loading i {
        margin-left: 0;
        margin-bottom: 15px;
        cursor: default;
    }

    input {
        width: 300px;
        max-width: 80%;
    }
}

.ipprint table {
    margin: auto;
    width: 100%;
}
