@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bounceIn {

    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: sans-serif;
}

.header {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 15px;

    .home {
        display: flex;
        gap: 10px;
        font-size: 22px;

        .link {
            height: max-content;
            margin: auto 0;
        }

        img {
            height: 60px;
        }
    }

    .links {
        display: flex;
        gap: 10px;

        .link {
            font-size: 18px;
        }
    }
}

.content {
    text-align: center;

    .title {
        font-size: 32px;
        margin-bottom: 10px;
    }
}

.footer {
    padding: 10px;
    padding-bottom: 15px;
    text-align: center;
    font-size: 16px;
}

.link {
    position: relative;
    cursor: pointer;

    &::after {
        position: absolute;
        content: "";
        transform-origin: 0 0;
        transform: scaleX(0);
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: currentColor;
        transition: transform 250ms;
    }

    &.active::after,
    &:hover::after {
        transform: scaleX(1);
    }
}

.link-container {
    cursor: pointer;

    &:hover .link::after {
        transform: scaleX(1);
    }
}

.line {
    border-color: black;
    border-bottom: solid black;
    border-width: 2px;
    margin: 10px 0;
    width: 100%;
}

.hint {
    display: inline-block;
    margin: 7px 0;
    margin-right: 5px;
    font-size: 18px;
}

.info {
    margin: 10px auto;
    width: max-content;
    padding: 10px;
    font-size: 18px;
    color: red;
    border: 1px solid;
    border-radius: 5px;
    animation-duration: 0.75s;
    animation-name: bounceIn;
}

.loading {
    width: 100%;
    text-align: center;

    i {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 32px;
        animation: spin 1s infinite linear;
    }
}

a {
    text-decoration: none;
    color: black;
}

input {
    padding: 5px;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;
}

button {
    margin: 5px;
    padding: 7px;
    background-color: rgb(225, 225, 225);
    font-size: 17px;
    cursor: pointer;
    border-width: 0;
    border-radius: 5px;
    transition-duration: 100ms;
    transition-property: background-color, transform;

    &:hover {
        background-color: rgb(200, 200, 200);
    }

    &:active {
        transform: translateY(2px);
    }
}

select {
    padding: 5px;
    background-color: rgb(225, 225, 225);
    font-size: 15px;
    cursor: pointer;
    border-width: 0;
    border-radius: 5px;
}

table {
    border-collapse: collapse;

    th,
    td {
        padding: 7px;
        border: 1px solid black;
    }
}